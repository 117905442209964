/**
 * @file
 * Styles for Social Media Menu
 * Hey, this goes well with the Social Media Menu Feature from The Wheel!
*/

@import "variables";

// Social Media Vars (uncomment if vars are not specified elsewhere)
$color-facebook:      #3b5998;
$color-twitter:       #00aced;
$color-pinterest:     #cb2027;
$color-google-plus:   #dd4b39;
$color-instagram:     #517fa4;
$color-youtube:       #bb0000;
$color-linkedin:      #007bb6;
$color-flickr:        #ff0084;
$color-tumblr:        #34465D;
$color-vimeo:         #1ab7ea;


// Fontello Font (fontello.com) - Specifically for Social Media Icons
@font-face {
  font-family: 'fontello-social';
  src: url('/sites/default/themes/thewheel/fonts/fontello-44e01232/font/fontello-social.eot?');
  src: url('/sites/default/themes/thewheel/fonts/fontello-44e01232/font/fontello-social.eot?#iefix') format('embedded-opentype'),
       url('/sites/default/themes/thewheel/fonts/fontello-44e01232/font/fontello-social.woff') format('woff'),
       url('/sites/default/themes/thewheel/fonts/fontello-44e01232/font/fontello-social.ttf') format('truetype'),
       url('/sites/default/themes/thewheel/fonts/fontello-44e01232/font/fontello-social.svg#fontello-social') format('svg');
  font-weight: bold;
  font-weight: normal; 
  font-style: normal;
}

// Menu Pane
#block-menu-menu-social-media-menu {
  padding: 0 0 10px;
  margin: 0 0 10px;
  background: $orange;
  @media only screen and (max-width: 767px) {
    background: $orange;
    padding: 0 0 10px;
    margin: 0;
  }

  ul.menu {
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      list-style: none;
      display: inline-block;
      padding: 0;
      margin: 0 3px 0 0;
    }
    
    a {
      display: block;
      height: 30px;
      width: 30px;
      background: red;
      font-size: 0;
      color: transparent;
      border: none;
      position: relative;
      @include border-radius(2px);
      
      &:before {
        font-family: fontello-social;
        color: $white;
        font-size: 19px;
        position: absolute;
        top: 5px;
        left: 9px;
      }
      
      // Facebook
      &.facebook {
        background: $color-facebook;
        &:before {
          content: "F";
          top: 5px;
          left: 9px;
        }
      }
      
      // Twitter
      &.twitter {
        background: $color-twitter;
        &:before {
          content: "T";
          font-size: 18px;
          left: 4px;
        }        
      }
      
      // Tumblr
      &.tumblr {
        background: $color-tumblr;
        &:before {
          content: "t";
          left: 8px;
        }        
      }

      // Pinterest
      &.pinterest {
        background: $color-pinterest;
        &:before {
          content: "P";
          left: 6px;
        }      
      }
      
      // Google Plus
      &.googleplus {
        background: $color-google-plus;
        &:before {
          content: "G";
          left: 3px;
        }
      }
      
      // Instagram
      &.instagram {
        background: $color-instagram;
        &:before {
          content: "I";
          left: 5px;
        }
      }

      // Flickr
      &.flickr {
        background: $color-flickr;
        &:before {
          content: "f";
          left: 5px;
        }      
      }

      // Youtube
      &.youtube {
        background: $color-youtube;
        &:before {
          content: "Y";
          font-size: 23px;
          left: 5px;
          top: 4px;
        }      
      }
      
      // Vimeo
      &.vimeo {
        background: $color-vimeo;
        &:before {
          content: "V";
          left: 4px;
        }      
      }
      
      // Linkedin
      &.linkedin {
        background: $color-linkedin;
        &:before {
          content: "L";
          left: 5px;
        }      
      }      
    }
  }
}
